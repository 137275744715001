.slider {
  -webkit-appearance: none;
  width: 100%;
  height: 8px;
  background: #e9ecef;
  border-radius: 5px;
  outline: none;
}

.slider::-webkit-slider-thumb {
  -webkit-appearance: none;
  appearance: none;
  width: 20px;
  height: 20px;
  background: #007bff;
  border-radius: 50%;
  cursor: pointer;
}

.slider::-moz-range-thumb {
  width: 20px;
  height: 20px;
  background: #007bff;
  border-radius: 50%;
  cursor: pointer;
}

.config-name {
    width: 200px;
}

.add-message:hover {
  cursor: pointer;  
  background: #e7e7e7;
}

.add-message > img {
  width: 16px;
  height: 16px;
}

.add-message > p {
  color: #5F6168;
  font-size: 14.9px;
  font-weight: 600;
}

.message-role {
  font-size: 15px;
  font-weight: 600;
  width: fit-content;
}

.message-role:hover {
  background: #e7e7e7;
}

.message-input {
  border: none;
  resize: none;
  white-space: pre-wrap;
  overflow: hidden;
  word-wrap: break-word;
  height: auto;
  outline: none;
  color: #736868;
  font-size: 13px;
}

.message-input-active {
  border: 1px solid #321fdb;
}

.message-input:active,
.message-input:focus {
  box-shadow: none;
  border: 1px solid #321fdb;
}

.delete-icon {
  color: #8C8585;
  width: 18px;height: 18px;
}

.ai-output {
  min-height: 200px ;
}

.btn-filter {
  background: #fff;
  border: 1px solid rgba(51, 51, 51, 0.1);
  border-radius: 6px;
  padding: 10px 17px;
  margin-bottom: 10px;
}

.btn-filter .color-filter {
  font-weight: 400;
  font-size: 13px;
}

.block-filter-component {
  margin-right: 10px;
  position: relative;
}

.block-filter-component:last-child {
  margin-right: 0;
}

@media (max-width: 576px) {
  .btn-filter {
      width: 100%;
  }

  .btn-filter>svg {
      margin-left: auto;
  }

  .block-filter-component {
      margin-right: 0;
  }
}

.date-picker {
  position: absolute;
  z-index: 999;
}

.select-filter-component {
  position: absolute;
  width: 100%;
  z-index: 100;
}

.box-select {
  background: #ffff;
  border-radius: 4px;
  min-width: 160px;
  padding: 5px 0px 12px;
  overflow: auto;
  overflow-x: hidden;
  border: 1px solid rgba(51, 51, 51, 0.1);
  box-shadow: 0px 4px 23px rgba(0, 0, 0, 0.05);
}

.radio-btn {
  position: absolute;
  opacity: 0;
  visibility: hidden;
}

.radio-btn:checked+.box-select-label::before {
  border: 0.35rem solid #3c65e0;
}

.box-select-label::before {
  display: inline-block;
  content: "";
  height: 1.25rem;
  width: 1.25rem;
  margin-right: 0.625rem;
  border: 0.1rem solid #cccccc;
  background: #fcfcfc;
  border-radius: 50%;
}

.box-select-label {
  display: flex;
  align-items: center;
  margin: 15px 0;
}

.box-select-label>p {
  margin-bottom: 0;
}

.hidden {
  display: none;
}

.table-answer thead th {
  position: sticky;
  top: 0;
  background-color: white;
}

.table-answer {
  border-spacing: 0;
  border-collapse: separate;
}

.data-block div {
  max-height: 300px;
}